<template>
  <div class="add-form">
    <el-form :model="pvForm" ref="form" size="small" label-position="top" :rules="pvRules">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item :label="$t('name')" prop="metadata.name">
        <el-input v-model.trim="pvForm.metadata.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item :label="$t('storageClassName')" prop="spec.storageClassName">
        <el-select
          v-model="pvForm.spec.storageClassName"
          :loading="storageClassLoading"
          @focus="initStorageClass"
          filterable
          style="width: 100%;"
        >
          <el-option
            v-for="item in storageClassOptions"
            :key="item.metadata.name"
            :label="item.metadata.name"
            :value="item.metadata.name"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('tag')">
        <add-labels :labels.sync="pvForm.metadata.labels" />
        <span class="help">{{ $t("tips.addlabels") }}</span>
      </el-form-item>

      <el-form-item :label="$t('accessModes')">
        <el-radio-group v-model="pvForm.spec.accessModes[0]">
          <el-radio-button label="ReadWriteOnce"></el-radio-button>
          <el-radio-button label="ReadOnlyMany"></el-radio-button>
          <el-radio-button label="ReadWriteMany"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('storage')">
        <el-input v-model="pvForm.spec.capacity.storage" style="width: 200px;">
          <template slot="append">Gi</template>
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('persistentVolumeReclaimPolicy')">
        <el-radio-group v-model="pvForm.spec.persistentVolumeReclaimPolicy">
          <el-radio-button label="Retain"></el-radio-button>
          <el-radio-button label="Delete"></el-radio-button>
          <el-radio-button label="Recycle"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('volumeMode')">
        <el-radio-group v-model="pvForm.spec.volumeMode">
          <el-radio-button label="Filesystem"></el-radio-button>
          <!-- <el-radio-button label="Block"></el-radio-button> -->
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('persistentVolumeSource')">
        <el-radio-group v-model="persistentVolumeSource">
          <el-radio-button label="AWSElasticBlockStore"></el-radio-button>
          <el-radio-button label="NFS"></el-radio-button>
          <el-radio-button label="Local"></el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-card shadow="never" style="margin-bottom: 18px;" v-if="persistentVolumeSource == 'AWSElasticBlockStore'">
        <div slot="header">{{ persistentVolumeSource }}</div>
        <el-form-item :label="$t('volumeID')" prop="spec.awsElasticBlockStore.volumeID">
          <el-input v-model.trim="pvForm.spec.awsElasticBlockStore.volumeID"></el-input>
        </el-form-item>

        <el-form-item :label="$t('fsType')" prop="spec.awsElasticBlockStore.fsType">
          <el-input v-model.trim="pvForm.spec.awsElasticBlockStore.fsType"></el-input>
        </el-form-item>

        <el-form-item :label="$t('readonly')">
          <el-switch v-model.trim="pvForm.spec.awsElasticBlockStore.readOnly"></el-switch>
        </el-form-item>
      </el-card>

      <el-card shadow="never" style="margin-bottom: 18px;" v-if="persistentVolumeSource == 'NFS'">
        <div slot="header">{{ persistentVolumeSource }}</div>
        <el-form-item :label="$t('nfsServer')" prop="spec.nfs.server">
          <el-input v-model.trim="pvForm.spec.nfs.server"></el-input>
        </el-form-item>

        <el-form-item :label="$t('nfsPath')" prop="spec.nfs.path">
          <el-input v-model.trim="pvForm.spec.nfs.path"></el-input>
        </el-form-item>

        <el-form-item :label="$t('readonly')">
          <el-switch v-model.trim="pvForm.spec.nfs.readOnly"></el-switch>
        </el-form-item>
      </el-card>

      <el-card shadow="never" style="margin-bottom: 18px;" v-if="persistentVolumeSource == 'Local'">
        <div slot="header">{{ persistentVolumeSource }}</div>
        <el-form-item :label="$t('path')" prop="spec.local.path">
          <el-input v-model.trim="pvForm.spec.local.path"></el-input>
          <span class="help">The full path to the volume on the node. </span>
        </el-form-item>

        <el-form-item :label="$t('fsType')" prop="spec.local.fsType">
          <el-input v-model.trim="pvForm.spec.local.fsType"></el-input>
          <span class="help">
            Filesystem type to mount.
            <br />
            Must be a filesystem type supported by the host operating system.
            <br />

            Ex. "ext4", "xfs", "ntfs". The default value is to auto-select a fileystem if unspecified.
          </span>
        </el-form-item>

        <el-form-item :label="$t('nodeAffinity')">
          <div slot="label">
            <div>{{ $t("nodeAffinity") }}</div>
          </div>

          <el-button
            type="default"
            size="small"
            style="margin-bottom: 10px; "
            plain
            icon="plus"
            @click="
              pvForm.spec.nodeAffinity.required.nodeSelectorTerms[0].matchExpressions.push({
                key: '',
                operator: 'In',
                values: []
              })
            "
          >
            + {{ $t("handle.addNodeLabels") }}
          </el-button>

          <el-row
            :gutter="20"
            v-for="(item, index) in pvForm.spec.nodeAffinity.required.nodeSelectorTerms[0].matchExpressions"
            :key="index"
          >
            <el-col :span="11">
              <el-form-item>
                <el-input v-model="item.key" size="small">
                  <template slot="prepend">
                    Key
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <el-form-item>
                <el-input v-model="item.values[0]" size="small">
                  <template slot="prepend">
                    Value
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="2">
              <el-button
                icon="el-icon-delete"
                circle
                @click="pvForm.spec.nodeAffinity.required.nodeSelectorTerms[0].matchExpressions.splice(index, 1)"
                size="small"
              ></el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-card>

      <el-form-item>
        <el-button type="primary" @click="submit" size="small" :loading="loading" plain>
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { includes, cloneDeep } from "lodash";
import CheckoutList from "@/components/CheckoutList";
import { pvSubmit } from "api/pv";
import { storageClassListByLocation } from "api/storageClass";
export default {
  components: {
    CheckoutList
  },

  data() {
    return {
      locationList: [],
      loading: false,
      pvForm: {
        metadata: {
          name: "",
          namespace: "",
          labels: {}
        },

        spec: {
          accessModes: ["ReadWriteOnce"],
          awsElasticBlockStore: {
            fsType: "",
            volumeID: "",
            readOnly: false
          },
          capacity: {
            storage: "2"
          },
          storageClassName: "",
          persistentVolumeReclaimPolicy: "Retain",
          volumeMode: "Filesystem"
        }
      },

      persistentVolumeSource: "AWSElasticBlockStore",

      pvRules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],

        "spec.storageClassName": { required: true, message: this.$t("rules.require"), trigger: "change" },

        "spec.awsElasticBlockStore.volumeID": { required: true, message: this.$t("rules.require"), trigger: "blur" },
        "spec.awsElasticBlockStore.fsType": { required: true, message: this.$t("rules.require"), trigger: "blur" },

        "spec.nfs.server": { required: true, message: this.$t("rules.require"), trigger: "blur" },
        "spec.nfs.path": { required: true, message: this.$t("rules.require"), trigger: "blur" },

        "spec.local.path": { required: true, message: this.$t("rules.require"), trigger: "blur" },
        "spec.local.fsType": { required: true, message: this.$t("rules.require"), trigger: "blur" }
      },

      storageClassLoading: false,
      storageClassOptions: []
    };
  },

  watch: {
    persistentVolumeSource(newVal) {
      delete this.pvForm.spec.awsElasticBlockStore;
      delete this.pvForm.spec.nfs;

      if (newVal == "AWSElasticBlockStore") {
        this.$set(this.pvForm.spec, "awsElasticBlockStore", { fsType: "", volumeID: "", readOnly: false });
      }

      if (newVal == "NFS") {
        this.$set(this.pvForm.spec, "nfs", { server: "", path: "", readOnly: false });
      }

      if (newVal == "Local") {
        this.$set(this.pvForm.spec, "local", { path: "", fsType: "" });
        this.$set(this.pvForm.spec, "nodeAffinity", {
          required: {
            nodeSelectorTerms: [
              {
                matchExpressions: []
              }
            ]
          }
        });
      }
    }
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },
    region() {
      return this.locationList[1];
    },
    cluster() {
      return this.locationList[2];
    }
  },

  methods: {
    initStorageClass() {
      if (this.locationList.length > 0) {
        this.storageClassLoading = true;
        storageClassListByLocation({ vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
          response => {
            if (response.code === 0) {
              this.storageClassLoading = false;
              this.storageClassOptions = response.data.items;
            }
          }
        );
      }
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;

          let data = cloneDeep(this.pvForm);
          if (!includes(data.spec.capacity.storage, "Gi")) data.spec.capacity.storage += "Gi";

          pvSubmit(data, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push({ path: `/list/PersistentVolume` });
            }
          });
        }
      });
    }
  }
};
</script>

<style></style>
